import React, { useEffect, useState } from "react"
import { useLoaderData } from "react-router-dom";
import { URLS } from "../App";
import '../css/scan.css'
import { Footer } from './Footer'
import shapescale_logo from '../static/shapescale-logo-footer.png'

export function loader({ request, params }) {
    const url = new URL(request.url)
    const token = url.searchParams.get('token')
    const scan = params.scanId;
    return { scan, token }
}


function base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    return stringToArrayBuffer(binary_string)
}

function stringToArrayBuffer(str) {
    var len = str.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = str.charCodeAt(i);
    }
    return bytes.buffer;
}

const additionalDataForScanId = (scanId) => {
    return `${scanId}textured_glbuser`
}

async function aesGCMDecrypt(aesKey, fileData, additionalData) {
    let iv = fileData.slice(4, 4 + 12)
    let data = fileData.slice(4 + 12)

    let aesKeyObj = await crypto.subtle.importKey(
        "raw",
        aesKey,
        { "name": "AES-GCM" },
        true,
        ["decrypt"]
    );

    let decrypted = crypto.subtle.decrypt(
        {
            name: "AES-GCM",
            iv: iv,
            additionalData: stringToArrayBuffer(additionalData)
        },
        aesKeyObj,
        data
    );
    return decrypted
}

let loading = false

export function Scan() {
    const { scan, token } = useLoaderData();
    const url = URLS.scan(scan)
    const [glb, setGlb] = useState(null);

    let scanUrl = new URL(url)
    const params = {
        token: token
    }
    Object.keys(params).forEach(key => scanUrl.searchParams.append(key, params[key]))

    const loadGlb = async () => {
        if (loading === true) {
            return
        }
        loading = true

        let res = await fetch(scanUrl)
        if (res.status === 200) {
            let jsonRes = await res.json()
            const glb = jsonRes.glb
            const key = jsonRes.key
            res = await fetch(glb, { mode: "cors" })
            if (res.status === 200) {
                const blob = await res.blob()
                const aesKey = base64ToArrayBuffer(key)
                const additional = additionalDataForScanId(scan)
                const blobArrayBuffer = await blob.arrayBuffer()
                try {
                    let decryptedFile = await aesGCMDecrypt(aesKey, blobArrayBuffer, additional)
                    let url = URL.createObjectURL(new Blob([decryptedFile]))
                    setGlb(url)
                } catch (err) {
                    console.error(err)
                }
            }
        }
    }
    useEffect(() => { loadGlb() })

    return (
        <div>
            <div id="top-section">
                <div id="scan-title">
                    SHAPESCALE
                </div>
                <div>
                    <model-viewer
                        src={glb}
                        camera-controls
                        touch-action="pan-y"
                        alt="3d model"
                    >
                    </model-viewer>
                </div>
                <div id="scan-text">
                    Want to know your measurements and how your body shape changes over time?
                </div>
                <div>
                    <a href="https://shapescale.com" target="_blank" rel="noreferrer">
                        <button className="default-button">
                            <img
                                id="scan-logo-in-button" src={shapescale_logo}
                                alt="logo"
                            >
                            </img>
                            &nbsp;Download ShapeScale App
                        </button>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default Scan
